import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import mq from '../styles/breakpoints'
import { baseTheme } from '../styles/theme'
import { COLORS } from '@borrowell/bw-styles'
import { IKnackCreditCard } from '../graphql/modules/knackProducts'

import { Heading, Superscript } from '../components/typography'
import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { Markdown } from '../components/typography/Markdown'
import { extractParamsFromUrl } from '../helpers/urlFunctions'
import { paramsService } from '../services/paramsService'

interface IProductDetailsSection {
  data: IKnackCreditCard
  ctaCopy?: string
  id: string | number
}

interface IProduct {
  propertiesData: { title: string; text: string }[]
}

interface IExpandable {
  isExpanded: boolean
  displayedLines?: string
  transitionTime?: number
  descriptionHeight?: number
}

const Section = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    margin: ['22px 20px', '15px 31px', '30px 42px'],
    boxShadow: `5px 12px 13px -13px ${COLORS.NEUTRAL.COOL['300']}`,
  })}
`
const SectionOne = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'space-between'],
    alignItems: 'center',
    padding: ['30px 27px', '30px', '30px 40px'],
  })}
`

const ProductInfo = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'space-between'],
    alignItems: 'center',
  })}
`

const PropertyCSS = css`
  div:last-child {
    border-right: none;
  }
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    alignItems: 'center',
    padding: ['0 27px', '0 46px', '0'],
    marginBottom: 0,
  })}
`

const SectionTwo = styled.dl`
  ${PropertyCSS}
`

const Property = styled.div`
  ${mq({
    width: ['50%', '50%', '100%'],
    height: ['70px', '70px', '110px'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: ['left', 'left', 'center'],
    borderTop: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
    borderBottom: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
    borderRight: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
  })}
`

const SectionThree = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  })}
`

const DescriptionSection = styled.div<IExpandable>(({ isExpanded }) =>
  mq({
    width: '100%',
    maxHeight: isExpanded ? '1000px' : '0px',
    padding: isExpanded ? ['17px 27px 23px 27px', '10px 46px 30px 46px', '20px 23px'] : 0,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      opacity: isExpanded ? '0' : '1',
      transition: 'opacity 400ms cubic-bezier(0.5, 0, 0.5, 1)',
      position: 'absolute',
      bottom: '0',
      height: '100%',
      width: '100%',
      content: "''",
      background: 'linear-gradient(to top, rgba(255,255,255, 1) 10%,  rgba(255,255,255, 0) 50%)',
      pointerEvents: 'none',
    },
    animationName: isExpanded ? 'growMaxHeight' : 'shrinkMaxHeight',
    animationTimingFunction: 'cubic-bezier(0.5, 0, 0.5, 1)',
    animationDuration: '200ms',
    '@keyframes growMaxHeight': {
      '0%': {
        maxHeight: '0px',
      },
      '100%': {
        maxHeight: '1000px',
      },
    },
    '@keyframes shrinkMaxHeight': {
      '0%': {
        maxHeight: '1000px',
      },
      '100%': {
        maxHeight: '0px',
      },
    },
  }),
)

const SectionFour = styled.div<IExpandable>(({ isExpanded }) =>
  mq({
    width: '100%',
    height: ['61px', '61px', '72px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: isExpanded ? `1px solid ${COLORS.NEUTRAL.COOL['300']}` : 'none',
  }),
)

const ExpandButton = styled.button`
  ${mq({
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.28', '1.28', '1.89'],
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    fontWeight: 'bold',
    padding: '10px',
    color: COLORS.PURPLE['700'],
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    ':hover,:active': {
      color: COLORS.PRIMARY.DEFAULT,
      outline: 'none',
      '& > div, & > div:before': {
        backgroundColor: COLORS.PRIMARY.DEFAULT,
      },
    },
  })}
`
const ExpandIcon = styled.div<IExpandable>(({ isExpanded }) =>
  mq({
    position: 'relative',
    alignSelf: 'center',
    transition: 'transform 0.5s ease',
    width: '20px',
    height: '3px',
    backgroundColor: COLORS.PURPLE['700'],
    transform: isExpanded ? 'rotate(180deg)' : '',
    borderRadius: '1px',
    marginLeft: '10px',
    '&:before': {
      display: 'block',
      content: '""',
      width: '20px',
      height: '3px',
      transform: isExpanded ? 'rotate(180deg)' : 'rotate(90deg)',
      transition: 'transform 0.5s ease',
      backgroundColor: COLORS.PURPLE['700'],
      borderRadius: '1px',
    },
  }),
)

const DetailsTitle = styled.dt(() =>
  mq({
    fontFamily: 'Lato',
    fontSize: ['12px', '12px', '17px'],
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '0',
    padding: ['0', '0', '15px 20px 0 20px'],
    alignSelf: 'flex-start',
    color: COLORS.NEUTRAL.COOL['900'],
  }),
)

const DetailsText = styled.dd(() =>
  mq({
    fontFamily: 'Lato',
    fontSize: ['19px', '19px', '21px'],
    textAlign: 'left',
    fontWeight: 'bold',
    lineHeight: '1.42',
    margin: '0',
    padding: ['1px 0', '1.7px 0', '15px 31px'],
    alignSelf: ['left', 'left', 'center'],
    color: COLORS.NEUTRAL.COOL['900'],
  }),
)

const imageStyles = {
  minWidth: ['266px', '266px', '210px'],
  height: ['165px', '167px', '142px'],
}

const productTitleStyles = {
  fontFamily: 'Lato',
  textAlign: ['center', 'center', 'left'],
  flexWrap: 'wrap',
  padding: ['19px 28px', '30px 0', '0 40px'],
}

const buttonStyles = {
  width: ['266px', '266px'],
  maxWidth: ['100%', '100%'],
  margin: 0,
  fontSize: ['14px', '18px', '18px'],
  lineHeight: ['1.86', '1.44', '1.44'],
  fontWeight: 'bold',
}

const someStyle = {
  paragraph: {
    textAlign: 'left',
    margin: '15px 22px',
    maxWidth: '855px',
  },
  list: {
    ...baseTheme.list,
    '& > li:last-of-type': {
      marginBottom: '20px',
    },
  },
  listElement: {
    maxWidth: '877px',
    paddingLeft: '22px',
    lineHeight: 1.44,
    margin: '15px 0px',
    '& > p': {
      margin: '15px 0px',
    },
  },
}

export const Properties: React.FC<IProduct> = ({ propertiesData }) => {
  return (
    <>
      {propertiesData.map(({ title, text }) => (
        <Property key={title}>
          <DetailsTitle>{title}</DetailsTitle>
          <DetailsText>{text || 'N/A'}</DetailsText>
        </Property>
      ))}
    </>
  )
}

const ProductDetailsSection: React.FC<IProductDetailsSection> = ({
  ctaCopy,
  data: {
    productName,
    url,
    marketingSiteUrl,
    description,
    image,
    annualFee,
    interestRate,
    cashAdvance,
    balanceTransferRate,
    __typename,
  },
  id,
}) => {
  const productTitle = productName.replace(/<[^>]+>/g, '')
  const imgAltText = `${productTitle} credit card`
  const properties = [
    { title: 'Annual Fee', text: annualFee },
    { title: 'Purchase Interest Rate', text: interestRate },
    { title: 'Cash Advance Rate', text: cashAdvance },
    { title: 'Balance Transfer Rate', text: balanceTransferRate },
  ]

  const link = url?.url ?? ''
  const [isExpanded, setIsExpanded] = useState(false)
  const [sanitizedUrl, setSanitizedUrl] = useState((marketingSiteUrl && marketingSiteUrl.url) || link)

  useEffect(() => {
    const sanitizeUrl = () => {
      if (marketingSiteUrl) {
        let { url } = marketingSiteUrl
        if (url.includes('{{mktg_site_session_id}}')) {
          url = paramsService.formatUrlWithMarketingSiteSessionId(url)
        }
        return setSanitizedUrl(url)
      }
      const [url, params] = link.split('?')

      const paramsObject: {
        [any: string]: string | undefined
      } = extractParamsFromUrl(params)

      const sanitizedParams = Object.entries(paramsObject)
        .filter(([, value]) => value && !value.includes('{CID}'))
        .map(([key, value]) => `${key}=${value}`)

      if (sanitizedParams.length === 0) return setSanitizedUrl(url)
      setSanitizedUrl(`${url}?${sanitizedParams.join('&')}`)
    }
    sanitizeUrl()
  }, [link, marketingSiteUrl])

  return (
    <Section>
      <SectionOne>
        <ProductInfo>
          {!!image && (
            <Image fluid={image.childImageSharp.gatsbyImageData} alt={imgAltText} customStyles={imageStyles} />
          )}
          <Heading as="h4" styleOverrides={productTitleStyles}>
            {productTitle && <Superscript>{productTitle}</Superscript>}
          </Heading>
        </ProductInfo>
        <Button
          to={sanitizedUrl}
          name="View Product"
          styleOverrides={buttonStyles}
          style={buttonStyles}
          ariaLabel={ctaCopy ? `${ctaCopy} ${productTitle}` : `View Product ${productTitle}`}
          rel="sponsored"
          contentfulId="N/A"
          typeName={__typename}
        >
          {ctaCopy || 'View Product'}
        </Button>
      </SectionOne>
      <SectionTwo>
        <Properties propertiesData={properties} />
      </SectionTwo>
      <SectionThree>
        <DescriptionSection isExpanded={isExpanded} id={`product-accordion-${id}`} aria-hidden={!isExpanded}>
          <Markdown withSuperscript customStyles={someStyle} contentfulId="N/A" typeName={__typename}>
            {description}
          </Markdown>
        </DescriptionSection>
      </SectionThree>
      <SectionFour isExpanded={isExpanded}>
        <ExpandButton
          name={isExpanded ? 'Hide Details' : 'Show Details'}
          onClick={() => setIsExpanded(curr => !curr)}
          aria-controls={`product-accordion-${id}`}
          aria-expanded={isExpanded}
          role="button"
          aria-label={isExpanded ? `Hide Details for ${productTitle}` : `Show Details for ${productTitle}`}
        >
          {isExpanded ? 'Hide Details' : 'Show Details'} <ExpandIcon isExpanded={isExpanded} />
        </ExpandButton>
      </SectionFour>
    </Section>
  )
}

export default ProductDetailsSection
